<template>
  <div class="role-main">
    <MyBreadcrumb></MyBreadcrumb>
    <div class="role-main-cont">
      <div class="search" >
        <div class="search-item">
          <div class="name">
            分类:
          </div>
          <div class="cont">
            <div class="values">
              <div :class="curCategoryId == item.id ? 'active' : ''" v-for="(item,fIndex) in categoryList" :key="item.id + 'category'" @click="changeSearch(item)">{{item.name}}</div>
            </div>
          </div>
        </div>
      </div>

      <el-table :data="data" border size="small" :header-cell-style="headerCellStyle">
        <el-table-column label="ID" prop="data.id" fixed="left"></el-table-column>
        <el-table-column :label="$t('dataset_usercollect_table_title')" min-width="300" fixed="left" show-overflow-tooltip>
          <template v-slot='{row}'>
            <router-link style="color:#2d8cf0" 
              :to="{
                name: 'my-detail',
                params: {
                  id: row.data.id,
                  tab:'cont'
                }}">
                {{ row.data.title }}
              </router-link>
          </template>
        </el-table-column>
        <el-table-column :label="$t('dataset_userwrong_table_type')">
          <template v-slot='{row}'>
            {{ datatypeObj[row.data.type] }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('dataset_data_table_level')">
          <template v-slot='{row}'>
            {{ datalevelObj[row.data.level] }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('dataset_usercollect_table_created_at')" min-width="100">
          <template v-slot='{row}'>
            {{ util.timeFormatter(new Date(+row.created_at * 1000), 'yyyy-MM-dd hh:mm') }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('dataset_usercollect_table_op')" width="150" fixed="right">
          <template v-slot='{row}'>
            <el-button size="mini" type="primary" @click="$router.push({
              name:'my-detail',
              params:{
                id: row.data.id,
                tab:'cont'
              }
            })">{{ $t('system_check') }}</el-button>
            <el-button size="mini" type="danger" @click="deleteItem(row)">{{ $t('system_dele') }}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="page">
        <Page :total="total" @on-change="changePage" :current="page" show-total show-sizer show-elevator></Page>
      </div>
    </div>

    <DeleModal :status="deleModalStatus" :txt="$t('dataset_usercollect_dele_tip')" :loading="modalLoading" @cancel="cancelDele" @confirm="confirmDele"></DeleModal>

  </div>
</template>

<script>
import DeleModal from '../../components/deleteModal.vue';
import util from '@/utils/tools.js';
export default {
  name: "mycollectView",
  data(){
    return{
      util,
      headerCellStyle:{
        backgroundColor: "#F8F8F9",
        color: "#666",
        fontWeight: "bold"
      },
      data: [],
      loading:false,
      deleteComfirmModal:true,
      deleModalStatus:false,
      modalLoading:false,
      curDeleData:{},
      page:1,
      pageSize:10,
      total:0,
      modalStatus:false,
      formItem:{
        id:'',
        name:'',
        data_count:'',
        desc:'',
      },
      ruleValidate: {
        id: [
          { required: true, message: this.$t('dataset_usercollect_add_id_tip'), trigger: 'blur' }
        ],
        name: [
          { required: true, message: this.$t('dataset_usercollect_add_name_tip'), trigger: 'blur' }
        ],
        data_count: [
          { message: this.$t('dataset_usercollect_add_data_count_tip'), trigger: 'blur' }
        ],
        desc: [
          { message: this.$t('dataset_usercollect_add_desc_tip'), trigger: 'blur' },
        ],
      },
      addBtnLoading:false,
      type:'',
      modalTit:'',
      datatypeObj:{},
      datalevelObj:{},
      categoryList:[],
      curCategoryId:'0',
    }
  },
  components:{
    DeleModal
  },
  mounted(){
    this.getList();
  },
  methods:{
    getList(){
      this.loading = true;
      let params = {
        action:'myCollectList',
        page:this.page,
        pageSize:this.pageSize,
        category_id:this.curCategoryId
      };
      this.api.dataset.trainExecute(params).then((res)=>{
        this.loading = false;
        if(!res.list.length && this.page > 1){
          this.page = this.page - 1;
          this.getList();
          return;
        }
        this.data = res.list;
        this.total = Number(res.count);
        this.datatypeObj = res.data_types;
        this.datalevelObj = res.data_levels;

        this.categoryList =[
          {
            name:'全部',
            id:0
          },
          ...res.category_list
        ]
      }).catch(()=>{
        this.loading = false;
      })
    },
    changeSearch(data){
      this.curCategoryId = data.id;
      this.getList();
    },
    deleteItem(data){
      this.curDeleData = data;
      this.deleModalStatus = true;
    },
    cancelDele(){
      this.deleModalStatus = false;
    },
    confirmDele(){
      let data = {
        id:this.curDeleData.id
      }
      this.modalLoading = true;
      this.api.dataset.dataUserCollectDelete(data).then((res)=>{
        this.modalLoading = false;
        this.deleModalStatus = false;
        this.getList();
      }).catch((err)=>{
        this.modalLoading = false;
      });
    },
    changePage(page){
      console.log(page,'changePage')
      this.page = page;
      this.getList();
    },
    cancel(){
      this.modalStatus = false;
      this.$refs.formCustom.resetFields();
    }
  }
}
</script>

<style scoped lang="scss">
.role-main{
  margin: 20px;

  .role-main-cont{
    padding:20px;
    background: #FFFFFF;
    box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
    border-radius: 4px;
  }
  .top{
    text-align: left;
    margin-bottom: 20px;
  }
  .page{
    margin-top: 20px;
    text-align: right;
  }
  .search{
    margin-top: 20px;
    margin-bottom: 20px;
    .search-item{
      margin-bottom: 15px;
      display: flex;
      justify-content: flex-start;
      font-size: 14px;

      .name{
        padding: 5px 0;
        width: 75px;
        text-align: right;
      }
      .cont{
        margin-left: 15px;
        flex: 1;
        display: flex;
        justify-content: space-between;

        .values{
          display: flex;
          justify-content: flex-start;
          flex-flow: wrap;
          transition: max-height 0.3s linear;
          overflow: hidden;

          >div{
            padding: 5px 10px;
            //margin: 0 10px 10px 5px;
            cursor: pointer;
          }
          >div.active{
            background-color: #2d8cf0;
            color:#FFFFFF;
            border-radius: 4px;
          }
        }
        .more{
          padding: 5px 0;
          width: 80px;
          text-align: right;
          cursor: pointer;
          .more-icon{
            transition: transform 0.3s linear;
          }
        }
      }
    }
  }
}
</style>
